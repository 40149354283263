import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ConfirmPopup from 'components/ConfirmPopup';
import useNotify from 'hooks/useNotify';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Login'))

const App = () => {
  const { contextHolder } = useNotify();
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        {contextHolder}
        <ConfirmPopup/>
        <Routes>
          <Route path="/login" name="Login Page" element={<Login />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
