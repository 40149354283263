import { IOpenMemberForm, IOpenConfirmPopup, IShowNotify } from 'types';

class EventEmitter {
  events: any;
  constructor() {
    this.events = {};
  }

  on(event, listener) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
    return () => this.removeListener(event, listener);
  }

  removeListener(event, listener) {
    if (typeof this.events[event] === 'object') {
      const idx = this.events[event].indexOf(listener);
      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
  }

  emit(event, ...args) {
    if (typeof this.events[event] === 'object') {
      this.events[event].forEach((listener) => listener.apply(this, args));
    }
  }

  once(event, listener) {
    const remove = this.on(event, (...args) => {
      remove();
      listener.apply(this, args);
    });
  }
};

const serviceEmitter = new EventEmitter();

export const EVENTS = {
  OPEN_MEMBER_FORM: 'open-member-form',
  OPEN_POPUP_CONFIRM: 'open-popup-confirm',
  SHOW_NOTIFY: 'show-notify',
};

const registerEvent = (eventName: string, listener: any) => serviceEmitter.on(eventName, listener);
const emitEvent = (eventName: string, arg?: any) => serviceEmitter.emit(eventName, arg);

interface EventListener {
  eventName: string;
  listener: (params?: any) => void;
}
export const initEvents = (EventListeners: EventListener[]) => {
  const unListeners: any[] = [];
    // eslint-disable-next-line
  EventListeners.map(({ eventName, listener }) => {
    unListeners.push(registerEvent(eventName, listener));
  });
  return () => {
    // eslint-disable-next-line
    unListeners.map((unListener) => {
      unListener();
    });
  }
};

export const openMemberForm = (options: IOpenMemberForm) => emitEvent(EVENTS.OPEN_MEMBER_FORM, options);
export const openPopupConfirm = (options: IOpenConfirmPopup) => emitEvent(EVENTS.OPEN_POPUP_CONFIRM, options);
export const showNotify = (options: IShowNotify) => emitEvent(EVENTS.SHOW_NOTIFY, options);