import { useEffect } from 'react';
import { message } from 'antd';
import { initEvents, EVENTS } from 'utils/eventEmitter';
import { IShowNotify } from 'types';

const useNotify = () => {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const destroyEvent = initEvents([{ eventName: EVENTS.SHOW_NOTIFY, listener: (options: IShowNotify) => {
      messageApi.open({ duration: 2, ...options });
    }
  }]);
  return destroyEvent;
  }, []);

  return { contextHolder };
};

export default useNotify;