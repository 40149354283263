import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import { EVENTS, initEvents } from 'utils/eventEmitter';
import { IOpenConfirmPopup } from 'types';

const ConfirmPopup: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const { title, confirmTxt = '', cancelTxt = '', component = () => <></> } = data;
  const callbackRef = useRef<{ onConfirm: (params?: any) => any, onClose: () => any }>({
    onConfirm: () => {},
    onClose: () => {}
  });

  useEffect(() => {
    const destroyEvents = initEvents([{ eventName: EVENTS.OPEN_POPUP_CONFIRM, listener: (options: IOpenConfirmPopup) => {
      const { onConfirm, onClose = () => {}, ...rest } = options;
      setData(rest);
      callbackRef.current = {
        onConfirm,
        onClose,
      }
      setOpen(true);
    }}]);
    return destroyEvents;
  }, []);

  const handleConfirm = async() => {
    setConfirmLoading(true);
    await callbackRef.current.onConfirm();
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setData({});
    callbackRef.current = {
      onConfirm: () => {},
      onClose: () => {},
    }
  };

  return (
    <Modal
      title={title}
      okText={confirmTxt}
      cancelText={cancelTxt}
      open={open}
      confirmLoading={confirmLoading}
      onOk={handleConfirm}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      {component}
    </Modal>
  );
};

export default ConfirmPopup;